
import {defineComponent, onMounted, reactive, ref, toRaw, toRefs} from "vue"
import type {UnwrapRef} from "vue"
import {IArticleCategory, IArticleUpdate, articleTypes} from "@/models/article_model"
import UploadComponent from "@/components/UploadComponent.vue"
import {message} from "ant-design-vue/es"
import {useRoute, useRouter} from "vue-router"
import EditorComponent from '@/components/editor/Editor.vue'
import {IArticlePlatformCate, IArticles} from "@/models/logistics_article_model";
import ApiLogistics from "@/request/apis/api_logistics";

interface IState {
  category: IArticlePlatformCate[],
  loading: boolean;
}

// 新增编辑文章
export default defineComponent({
  name: "ArticleSet",
  components: {UploadComponent, EditorComponent},
  setup() {
    const route = useRoute()
    const router = useRouter()
    let editId: any;
    const formRef = ref()
    const editorComponentRef = ref()
    const formState: UnwrapRef<IArticles> = reactive({
      "title":"",
      "content":"",
      "documentation_cate_id": null,
      "weight":0,
      "status": 1
    })
    const rules = {
      title: [{required: true, message: "请输入文章标题"},],
      documentation_cate_id: [{required: true, message: "请选择文章分类"},],
    }

    const onSubmit = () => {
      formRef.value
          .validate()
          .then(() => {
            let params = {...toRaw(formState)}
            params.content = editorComponentRef.value.getHtmlText()
            if (editId) {
              params.id = editId
              onEdit(params)
            } else {
              onAdd(params)
            }
          })
          .catch((error: any) => {
            console.log("error", error)
          })
    }
    const resetForm = () => {
      formRef.value.resetFields()
    }

    // 富文本
    const onEditorChange = (e: string) => {
      formState.content = e
    }

    const state: IState = reactive({
      category: [],
      loading: false,
    })

    const onTypeChange = (e: any) => {

    }

    // 获取分类列表
    const getCategoryList = async () => {
      const res = await ApiLogistics.articleCateTree({})
      if (res && res.error_code === 0) {
        state.category = res.data || []
      }
    }

    // 获取文章详情
    const getDetail = async () => {
      const {id} = route.query
      //IArticleDetail
      if (id) {
        editId = id
        const res = await ApiLogistics.articleDetail(id.toString())
        if (res && res.error_code === 0 && res.data) {
          const data = res.data
          formState.title = data.title
          formState.content = data.content
          formState.create_time = data.create_time;
          formState.del = data.del;
          formState.documentation_cate_id = data.documentation_cate_id;
          formState.status = data.status;
          formState.weight = data.weight;
        }
      }
    }

    // 新增
    const onAdd = async (params: any) => {
      state.loading = true
      const res = await ApiLogistics.updateOrAddArticle(params)
      state.loading = false
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg)
          resetForm()
          router.back()
          return
        }
        message.error(res.msg)
      }
    }

    // 编辑
    const onEdit = async (params: any) => {
      state.loading = true
      const res = await ApiLogistics.updateOrAddArticle(params)
      state.loading = false
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg)
          resetForm()
          router.back()
          return
        }
        message.error(res.msg)
      }
    }

    // 从html中获取图片集和
    const getImagesFromHtml = (htmlText: string) => {
      if (!htmlText) return ''
      const urls = Array.from(new DOMParser().parseFromString(htmlText, 'text/html')
          .querySelectorAll('img'))
          .map(img => img.getAttribute('src'));
      if (urls.length) {
        return urls.join(';')
      }
      return ''
    }

    const goBack = () => {
      router.back()
    }

    onMounted(() => {
      getDetail()
      getCategoryList()
    })

    return {
      formRef,
      labelCol: {span: 4},
      wrapperCol: {span: 14},
      other: "",
      formState,
      rules,
      onSubmit,
      resetForm,
      onEditorChange,
      onTypeChange,
      goBack,
      articleTypes,
      ...toRefs(state),
      editorComponentRef,
    }
  },
})
